import React, { useState } from "react";

const CertVerifier = () => {
  const [certificateInfo, setCertificateInfo] = useState([]);
  const [certificateCode, setCertificateCode] = useState("");
  const [error, setError] = useState("");

  const fetchData = (event) => {
    event.preventDefault();

    if (!certificateCode) {
      setError("Please enter a certificate code");
      return;
    }

    fetch(
      `https://certificate.dsev.online/backend/index.php?certificate_code=${certificateCode}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error");
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setCertificateInfo(data);
          setError("");
        } else {
          setCertificateInfo([]);
          setError("Keine Daten gefunden");
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        setError("Keine Daten gefunden");
        setCertificateInfo([]);
      });
  };

  return (
    <>
      <div className="mx-auto grid justify-center items-center">
        <div className="w-100% my-8 flex justify-center">
          <form className="p-8 bg-indigo-50  border-2 border-indigo-100 rounded-md lg:gap-0 gap-3 lg:flex grid">
            <input
              type="text"
              name="text"
              value={certificateCode}
              placeholder="Geben Sie den Zertifikatscode ein"
              onChange={(e) => {
                setCertificateCode(e.target.value);
                setError("");
              }}
              className="lg:w-[500px] sm:w-[350px] w-[200px] border-2  border-black px-2 py-2 rounded-sm"
            />
            <button
              onClick={fetchData}
              className="bg-black text-white font-bold py-2 px-8 border-2 border-black rounded-sm"
              type="submit"
            >
              Suchen
            </button>
          </form>
        </div>
        {Array.isArray(certificateInfo) && certificateInfo.length > 0 && (
          <div>
            <h1 className="font-bold text-[35px] text-center mb-6">
              Suchergebnis
            </h1>
            {certificateInfo.map((certificate, index) => (
              <ul key={index}>
                <div>
                  <li>
                    <p>Zertifikatscode</p>
                    <p>{certificate.certificate_code}</p>
                  </li>
                  <li>
                    <p>Teilnehmer</p>
                    <p>{certificate.student_name}</p>
                  </li>
                  <li>
                    <p>Kursname</p>
                    <p>{certificate.course_name}</p>
                  </li>
                </div>
                <div>
                  <li>
                    <p>Kursdauer</p>
                    <p>
                      {certificate.course_hours !== ""
                        ? certificate.course_hours
                        : "-"}
                    </p>
                  </li>
                  <li>
                    <p>Gültig bis</p>
                    <p>{certificate.dob}</p>
                  </li>
                  <li>
                    <p>Ausgabedatum</p>
                    <p> {certificate.award_date}</p>
                  </li>
                </div>
              </ul>
            ))}
          </div>
        )}
        {error && <p className="text-center text-red-500">{error}</p>}
      </div>
    </>
  );
};

export default CertVerifier;
