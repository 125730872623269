import CertVerifier from "./components/CertVerifier";
import "./iframeSizer.contentWindow.min.js";

function App() {
  return (
    <>
      <CertVerifier />
    </>
  );
}

export default App;
